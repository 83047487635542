import React from 'react';
import { css } from 'styled-components';
import { BadgesSection } from 'views/mobile-development/badges-section';
import {
    LATEST_RELATED_ARTICLES_SETTINGS,
    TILES_DATA,
    useCaseStudiesData,
} from 'views/mobile-development/constants';
import { PartnershipForYearsSection } from 'views/mobile-development/partnership-for-years-section';
import { MobileDevelopmentProcessSection } from 'views/mobile-development/process-section';
import { QualityThatMattersSection } from 'views/mobile-development/quality-that-matters-section';
import { AnimatedMobileDevelopment } from 'components/animated-mobile-development';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { AIAssistantSection } from 'modules/ai-assistant-section';
import { FooterSection } from 'modules/footer-section';
import { MainSection } from 'modules/main-section';
import { PerksWithImageSection } from 'modules/perks-with-image-section';
import { QuoteBannerSection } from 'modules/quote-banner-section';
import { SectionTilesSection } from 'modules/section-tiles-section';
import { ServicesCaseStudiesSection } from 'modules/services-case-studies-section';
import { array } from 'prop-types';

import { PERKS } from './constants';
import { MobileCtaSectionExtended } from './mobile-cta-section-extended';

const Props = {
    faqData: array.isRequired,
};

export const MobileDevelopmentView = ({ faqData }) => {
    return (
        <>
            <MainSection
                icon={<AnimatedMobileDevelopment />}
                iconWrapperCss={css`
                    max-width: 34.25rem;
                `}
                titleId="view-mobile-projects-main-section.title"
                subtitles={['view-mobile-projects-main-section.subtitle']}
                estimateProjectAnalytics={
                    gaTrackedEvents.MOBILE_DEVELOPMENT.CTA.ESTIMATE_PROJECT
                }
                intoCallAnalytics={
                    gaTrackedEvents.MOBILE_DEVELOPMENT.CTA.INTRO_CALL
                }
            />
            <SectionTilesSection data={TILES_DATA} />
            <QualityThatMattersSection />
            <AIAssistantSection />
            <QuoteBannerSection
                quote="quotes.remainingOnboard"
                author="quotes.andreaLopez"
                position="quotes.projectManagerCbre"
            />
            <PartnershipForYearsSection />
            <BadgesSection />
            <MobileDevelopmentProcessSection />
            <MobileCtaSectionExtended />
            <PerksWithImageSection
                title="view-mobile-projects-solution-section.title"
                perks={PERKS}
            />
            <ServicesCaseStudiesSection
                caseStudiesData={useCaseStudiesData()}
                subtitleId="case-studies.desc"
            />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </>
    );
};

MobileDevelopmentView.propTypes = Props;
